import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $heroLockup = $('.hero-lockup');
    if (!$heroLockup.length) {
        return;
    }
    setTimeout(function() {
        $heroLockup.removeClass('hero-lockup--unloaded');
    }, 250);
    const $actors = $heroLockup.find('.hero-lockup__actors').filter((i, el) => $(el).find('.content-column').length === 2);
    $actors.each((i, container) => {
        $(container).find('.content-column__inner .heading:first-child').on('click', (e) => {
            $(e.currentTarget).parents('.hero-lockup').toggleClass('hero-lockup--swapped');
        });
    });
    if (Math.floor(Math.random() * 2)) {
        $heroLockup.addClass('hero-lockup--swapped');
    }
});
