import {
    $, addAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    const $toggleActors = $('.toggle-actors');
    if (!$toggleActors.length) {
        return;
    }
    if (Math.floor(Math.random() * 2)) {
        $toggleActors.addClass('toggle-actors--swapped');
    }
});
